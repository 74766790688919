// Product.js
import React from 'react';
import './Product.css';
import { Link } from 'react-router-dom';

const Product = ({ product }) => {
  const imageUrl = product.image_route;
  return (
    <div className="product">
      <Link to={`/product/${product.id}`}>
        <img src={imageUrl} alt={product.name} className="product-image" />
        <h3 className="product-name">{product.name}</h3>
        <p className="product-price">{product.lowest_price}</p>
      </Link>
    </div>
  );
};

export default Product;
