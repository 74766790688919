import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './ProductDetail.css';

const ProductDetails = () => {
    const [productDetails, setProductDetails] = useState({});
    const [relatedProducts, setRelatedProducts] = useState([]);
    const { id } = useParams(); // get the id parameter from the URL
  
    useEffect(() => {
      const uniqueProductId = id; // use the id variable here
      console.log('The id of the product is:', id);
      fetch(`https://onlygamers.cl/api/products/related/${uniqueProductId}/`)
        .then((response) => response.json())
        .then((data) => {
          setProductDetails(data.unique_product);
          setRelatedProducts(data.related_products);
        })
        .catch((error) => {
          console.error(error);
        });
    }, [id]); // use id in the dependency array

  return (
    <div className="product-details">
      <div className="product-image">
        <img src={`https://onlygamers.cl/${productDetails.image_route}`} alt={productDetails.name} />
      </div>
      <div className="related-products">
        {relatedProducts.map((relatedProduct) => (
          <a href={relatedProduct.url} target="_blank" rel="noopener noreferrer" key={relatedProduct.name}>
            <button>
              {relatedProduct.website} - {relatedProduct.price}
            </button>
          </a>
        ))}
      </div>
    </div>
  );
};

export default ProductDetails;
