import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom'; // Import useNavigate
import './Header.css';
import logo from './logo.png';

const Header = ({ onSearch }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate(); // Replace useHistory with useNavigate

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    onSearch(searchQuery);
    navigate(`/?search=${searchQuery}`); // Use navigate instead of history.push
  };

  return (
    <header className="header">
      <div className="logo-container">
        <Link to="/">
          <img src={logo} alt="Logo" className="logo" />
        </Link>
      </div>
      <form onSubmit={handleSearchSubmit} className="search-container">
        <input
          type="text"
          placeholder="Search"
          className="search-input"
          value={searchQuery}
          onChange={handleSearchChange}
        />
        {/* Button removed */}
      </form>
      <div className="nav-container">
        <button className="nav-button">Placas Madre</button>
        <button className="nav-button">Hardware</button>
        <button className="nav-button">Periféricos</button>
        <button className="nav-button">Electro</button>
      </div>
    </header>
  );
};

export default Header;
