import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import Header from './Header/Header';
import ProductGrid from './Product/ProductGrid';
import ProductDetails from './Product/ProductDetail';

function Content() {
  const location = useLocation();
  const searchQuery = new URLSearchParams(location.search).get('search');
  const [products, setProducts] = useState([]);

  const loadProducts = (query) => {
    const url = query
      ? `/api/products/unique_products/?search=${query}`
      : '/api/products/unique_products/';

    fetch(url)
      .then((response) => response.json())
      .then((data) => setProducts(data))
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    loadProducts(searchQuery);
  }, [searchQuery]);

  const handleSearch = (query) => {
    loadProducts(query);
  };

  return (
    <div className="App">
      <Header onSearch={handleSearch} />
      <Routes>
        <Route path="/product/:id" element={<ProductDetails />} />
        <Route path="/" element={<ProductGrid products={products} />} exact />
        {/* Add more routes as needed */}
      </Routes>
    </div>
  );
}

function App() {
  return (
    <Router>
      <Content />
    </Router>
  );
}

export default App;
